




















































import { defineComponent, ref, Ref, PropType, computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

export default defineComponent({
  name: 'FcCheckTableDialog',
  props: {
    // 選択された一覧
    value: {
      type: Array as PropType<Record<string, any>>,
      required: false,
      default: [],
    },
    // 画面に表示する一覧
    lists: {
      type: Array as PropType<object[]>,
      required: true,
      default: [],
    },
    // テーブルに表示する項目
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
      default: [],
    },
    // ボタンに表示する文言
    label: {
      type: String,
      required: true,
      default: '追加',
    },
    // v-chipを使用するかどうかのフラグ
    isChip: {
      type: Boolean,
      required: false,
      default: false,
    },
    // チェックするためのユニークキー
    checkId: {
      type: String,
      required: true,
      default: '',
    },
    addSelectedItems: {
      type: Function as PropType<(selectedItems: Record<string, any>) => void>,
      required: true,
    },
    // ページネーションのデフォルト設定
    pagination: {
      type: Object,
      required: false,
      default: {
        page: 1,
        itemsPerPage: 10,
      },
    },
    // ページネーションの設定
    footerProps: {
      type: Object,
      required: false,
      default: {
        'items-per-page-text': '行/ページ:',
        'items-per-page-options': [10, 20, 50, 100, -1],
      },
    },
    // ステータスの表示などでv-chipを使用したい場合の色や表示する文言の条件を渡す
    chipCondition: {
      type: Function as PropType<chipConditionType>,
      required: true,
    },
  },
  setup(props) {
    const isActiveDialog = ref(false); // ダイアログ表示フラグ
    const searchWord = ref(''); // 検索ワード
    const selectedItems: Ref<Record<string, any>> = ref(props.value); // 選択された楽曲一覧
    // 画面表示用一覧
    const displayLists = computed(() => {
      return props.lists?.filter((list) => !searchWord.value || JSON.stringify(list).includes(searchWord.value));
    });
    const tableHeight = ref(450); // テーブル枠の高さ

    // 選択したリストを一覧に追加
    const addList = () => {
      props.addSelectedItems(selectedItems.value);
      searchWord.value = '';
      isActiveDialog.value = false;
      // 初期表示時にダイアログチェックを使用しない場合のために、チェックはリセットしておく
      selectedItems.value = [];
    };

    // ダイアログをキャンセルした時：チェックボックスを変更前の状態に戻す
    const cancelDialog = () => {
      selectedItems.value = props.value;
      searchWord.value = '';
      isActiveDialog.value = false;
    };

    return {
      isActiveDialog,
      searchWord,
      selectedItems,
      displayLists,
      tableHeight,
      addList,
      cancelDialog,
    };
  },
});
