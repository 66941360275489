






































































































































































































import { computed, defineComponent, ref, Ref, PropType } from '@vue/composition-api';
import FcDate from '@/components/FcDate.vue';
import { Musics, usePlayList, DisplayMusics, SelectedMusic } from '@/composition/music';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcCheckTableDialog from '@/components/FcCheckTableDialog.vue';
import { useNotification } from '@/composition/notification';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import draggable from 'vuedraggable';

export default defineComponent({
  name: 'PlayList',
  components: {
    FcDate,
    FcImage,
    FcStaticImage,
    FcCheckTableDialog,
    FcRoleLoading,
    FcRoleDeny,
    draggable,
  },
  props: {
    // 編集するプレイリストのプレイリストId
    playlistId: { type: String, require: false },
    // 楽曲が選択された状態で新規登録画面に遷移したときに選択されている楽曲一覧
    selectedMusics: {
      type: Array as PropType<SelectedMusic[]>,
      require: false,
      default: () => [],
    },
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('musics'));
    const musics = Musics.getInstance();
    const playList = usePlayList();
    const notification = useNotification();

    const isLoading = ref(false); // ロード中フラグ
    const isSaving = ref(false); // DB更新中フラグ
    const searchWord = ref(''); // 検索ワード
    const editMusics: Ref<DisplayMusics[]> = ref([]); // 登録・編集するプレイリストに登録する楽曲一覧
    const firstMusics: Ref<string[]> = ref([]); // 新規登録時の初期表示時点で選択されている楽曲一覧
    // ダイアログに表示する楽曲一覧
    const displayMusicsInDialog = computed(() =>
      musics.musics?.filter((list) => !editMusics.value.some((editMusic) => editMusic.musicId === list.musicId))
    );
    // 画面表示用楽曲一覧
    const displayMusics = computed(() => {
      // 検索キーワードで絞り込み
      return editMusics.value?.filter(
        (playlist) => !searchWord.value || JSON.stringify(playlist).includes(searchWord.value)
      );
    });
    const typeOptions = [
      { text: 'アルバム', value: 'album' },
      { text: 'シングル', value: 'single' },
      { text: 'プレイリスト', value: 'playlist' },
    ];
    const tab = 0; // currentになる、タブの初期値
    const tabItems = ['情報登録', '登録楽曲']; // タブ項目
    // 一覧に表示する項目
    const headers = [
      { text: '', value: 'handle', sortable: false },
      { text: 'ステータス', value: 'streamStatus' },
      { text: '楽曲名', value: 'musicName' },
      { text: 'アーティスト名', value: 'artistName' },
      { text: 'アルバム', value: 'albumName' },
      { text: '', value: 'actions', sortable: false },
    ];
    // ページネーションデフォルト設定
    const pagination = {
      page: 1,
      itemsPerPage: 100,
    };
    const tableHeight = window.innerHeight - 163; // テーブル枠の高さ

    /**
     * プレイリストの楽曲一覧に選択した楽曲を追加する
     *
     * @param addSelectedItems - 選択した楽曲を追加する関数
     */
    const addSelectedMusics = (selectedItems: DisplayMusics[]) => {
      editMusics.value = editMusics.value.concat(selectedItems);
    };

    /**
     * ステータスの表示などでv-chipを使用したい場合の色や表示する文言の条件を返す
     *
     * @param status - ステータス
     * @returns 条件オブジェクト
     */
    const chipCondition = (status: string) => {
      if (status === 'before') {
        return { name: '配信前', color: 'warning' };
      } else if (status === 'now') {
        return { name: '配信中', color: 'primary' };
      } else if (status === 'finish') {
        return { name: '配信終了', color: 'error' };
      } else {
        return { name: '', color: '' };
      }
    };

    /**
     * プレイリスト登録・編集処理
     *
     * @param playlist - 登録・編集するプレイリストの楽曲一覧
     */
    const savePlayList = async () => {
      isSaving.value = true;
      try {
        const musicIds = editMusics.value.map((value) => {
          return value.musicId ? value.musicId : '';
        });
        await playList.savePlayList(musicIds);
        playList.playlist.playlistId
          ? notification.notify('プレイリストを編集しました')
          : notification.notify('プレイリストを登録しました');
        context.root.$router.push('/playlists');
      } catch (e) {
        notification.error(e);
      } finally {
        isSaving.value = false;
      }
    };

    /**
     * 楽曲一覧から指定した楽曲Idの楽曲一覧を取得し、プレイリストに登録されている順番に並び替える
     *
     * @param musicIds - 楽曲Id
     * @returns 指定した楽曲Idの楽曲一覧
     */
    const filterPlayListMusics = (musicIds: string[]) => {
      const result = musics.musics?.filter((music) => (music.musicId ? musicIds.includes(music.musicId) : false));
      result?.sort((a, b) => {
        return (
          firstMusics.value.indexOf(a.musicId ? a.musicId : '') - firstMusics.value.indexOf(b.musicId ? b.musicId : '')
        );
      });
      return result;
    };

    /**
     * プレイリストの楽曲一覧から指定した楽曲を削除する
     *
     * @param item - 削除する楽曲情報
     */
    const deleteEditMusics = (item: DisplayMusics) => {
      // プレイリストに登録する楽曲一覧から削除
      const index = editMusics.value.indexOf(item);
      if (index !== -1) {
        editMusics.value.splice(index, 1);
      }
    };

    /**
     * 初期表示処理
     */
    const init = async () => {
      isLoading.value = true;

      try {
        // 楽曲一覧を取得する
        await musics.getMusics();

        if (!props.playlistId) {
          // 新規登録の場合は選択されている楽曲一覧を取得する
          firstMusics.value = props.selectedMusics.map((music) => {
            return music.musicId ? music.musicId : '';
          });
          const playListMusics = filterPlayListMusics(firstMusics.value);
          if (playListMusics) {
            // プレイリスト登録用に選択されている楽曲がある場合、プレイリスト登録楽曲一覧に追加する
            editMusics.value.push(...playListMusics);
          }
          return;
        }

        // 編集の場合はプレイリスト情報を取得する
        await playList.getPlayList(props.playlistId);
        firstMusics.value = playList.playlist.musicIds;
        const playListMusics = filterPlayListMusics(playList.playlist.musicIds);
        if (!(playListMusics && playListMusics.length !== 0)) {
          throw '登録楽曲がない状態でプレイリストが公開されていますので、ご確認ください。';
        }
        // プレイリストに登録されている楽曲情報を追加する
        editMusics.value.push(...playListMusics);
      } catch (e) {
        notification.error(e);
      } finally {
        isLoading.value = false;
      }
    };

    init();

    return {
      pageTitle: props.playlistId ? 'プレイリスト編集' : 'プレイリスト登録',
      myRoleSettings,
      musics,
      playList,
      isLoading,
      isSaving,
      searchWord,
      editMusics,
      displayMusics,
      typeOptions,
      tab,
      tabItems,
      headers,
      pagination,
      tableHeight,
      addSelectedMusics,
      chipCondition,
      savePlayList,
      deleteEditMusics,
      displayMusicsInDialog,
    };
  },
});
